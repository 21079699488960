import Turbolinks from 'turbolinks'
import Glide from '@glidejs/glide'
import SweetScroll from 'sweet-scroll'
import 'alpinejs'
import 'typeface-open-sans'
import 'typeface-titillium-web'

import '../css/application.scss'
require.context('../images', true)

Turbolinks.start()

document.addEventListener('turbolinks:load', function () {
  if (document.querySelector("[data-glide='clients']")) {
    new Glide(
        "[data-glide='clients']", {
            type: 'carousel',
            autoplay: 2000,
            animationDuration: 6000,
        }).mount();
  }

  if (document.querySelector("[data-glide='products']")) {
    new Glide(
        "[data-glide='products']", {
            type: 'carousel',
            autoplay: 2000,
            animationDuration: 6000,
        }).mount();
  }

  new SweetScroll()
})
